import styled from "styled-components"

import { ParadiseProfileButton } from "src/components/AppLayout/ParadiseProfileButton"
import { RecentlyViewedPopover } from "src/components/RecentlyViewed/RecentlyViewedPopover"
import { RequireRoles } from "src/components/RestrictedRoles/RequireRoles"
import { UserRoles } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { backgroundColor, mColors, palette } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import CartIcon from "src/ui/icons/cart.svg"
import CodeIcon from "src/ui/icons/code.svg"
import HomeIcon from "src/ui/icons/home.svg"
import Logo from "src/ui/icons/logo.svg"
import OrganizationIcon from "src/ui/icons/organization.svg"
import SensorIcon from "src/ui/icons/sensor.svg"
import SettingsIcon from "src/ui/icons/sliders.svg"
import StopwatchIcon from "src/ui/icons/stopwatch.svg"
import TeamsIcon from "src/ui/icons/teams.svg"
import ToolIcon from "src/ui/icons/tool.svg"
import { spacing } from "src/ui/spacing"

import {
  IconBox,
  LogoBox,
  NavFooter,
  NavMain,
  ProfileButtonSpacer,
  SharedStyledNavLink,
  SideNavBox,
} from "./sharedStyles"

export function ParadiseSideNav({
  navLinkClick = () => {},
  ...props
}: {
  navLinkClick?: () => void
}) {
  const { t } = useTranslate()

  return (
    <SideNavBox {...props} aria-label="navbar" $background={palette.hejmo}>
      <LogoBox>
        <Logo width={70} fill="#fff" />
      </LogoBox>

      <ProfileButtonSpacer>
        <ParadiseProfileButton />
      </ProfileButtonSpacer>

      <NavMain
        $color={mColors.textContrast}
        $hoverBgColor="rgba(255, 255, 255, 0.15)"
        $activeBgColor="rgba(255, 255, 255, 0.15)"
        $activeColor={mColors.textContrast}
      >
        <li>
          <StyledNavLink
            to={Routes.ParadiseUsers.location()}
            onClick={navLinkClick}
          >
            <IconBox>
              <TeamsIcon />
            </IconBox>
            Users
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink
            to={Routes.ParadiseHomes.location()}
            onClick={navLinkClick}
          >
            <IconBox>
              <HomeIcon />
            </IconBox>
            {t(langKeys.home, { count: 2 })}
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink
            to={Routes.ParadiseDevices.location()}
            onClick={navLinkClick}
          >
            <IconBox>
              <SensorIcon />
            </IconBox>
            Devices
          </StyledNavLink>
        </li>

        <li>
          <StyledNavLink
            to={Routes.ParadiseOrganizations.location().pathname}
            onClick={navLinkClick}
          >
            <IconBox>
              <OrganizationIcon />
            </IconBox>
            {t(langKeys.organization)}
          </StyledNavLink>
        </li>
        <RequireRoles roles={[UserRoles.SUPERADMIN]}>
          <li>
            <StyledNavLink
              to={Routes.ParadiseProfiles.location()}
              onClick={navLinkClick}
            >
              <IconBox>
                <SettingsIcon color="white" />
              </IconBox>
              Profiles
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink
              to={Routes.ParadiseClients.location()}
              onClick={navLinkClick}
            >
              <IconBox>
                <CodeIcon color="white" />
              </IconBox>
              Clients
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink
              to={Routes.ParadiseFirmwares.location()}
              onClick={navLinkClick}
            >
              <IconBox>
                <ToolIcon />
              </IconBox>
              Firmwares
            </StyledNavLink>
          </li>
        </RequireRoles>

        <li>
          <StyledNavLink
            to={Routes.ParadiseOrders.location()}
            onClick={navLinkClick}
          >
            <IconBox>
              <CartIcon />
            </IconBox>
            Orders
          </StyledNavLink>
        </li>

        <StyledDivider />

        <li>
          <RecentlyViewedPopover
            trigger={
              <StyledNavLink to="" isActive={() => false}>
                <IconBox>
                  <StopwatchIcon fill={mColors.neutralLight} />
                </IconBox>
                Recently viewed
              </StyledNavLink>
            }
          />
        </li>
      </NavMain>

      <NavFooter></NavFooter>
    </SideNavBox>
  )
}

const StyledNavLink = styled(SharedStyledNavLink)`
  color: ${backgroundColor};
`

export const StyledDivider = styled(Divider)`
  margin: ${spacing.M} 0;
  background: ${mColors.divider}20;
`
